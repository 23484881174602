<template>
  <div
    class="relative w-full h-full"
    @mouseover="hoverVideo(true)"
    @mouseleave="hoverVideo(false)"
  >
    <div
      v-if="animationUrl"
      class="w-full h-full relative overflow-hidden lg:group-hover/card:scale-[1.08] transition-all duration-300"
    >
      <video
        ref="VideoRef"
        :key="animationUrl"
        class="transition-all z-10 absolute max-w-none w-full-plus h-full-plus -top-[1px] -left-[1px]"
        :class="classesComp"
        :alt="alt"
        :width="width"
        :height="height"
        playsinline
        muted
        loop
        disableremoteplayback
        draggable="false"
        preload="metadata"
        :autoplay="forceVideoAutoplay"
        @loadedmetadata="autoPlayVideo"
      >
        <source :src="`${animationUrl}#t=0.1`" type="video/mp4">
      </video>
    </div>

    <img
      v-else
      class="z-10 relative lg:group-hover/card:scale-[1.08] transition-all  duration-300"
      :class="[
        classesComp,
        loaded ? 'opacity-100' : 'opacity-0'
      ]"
      :src="imageUrl"
      :alt="alt"
      :width="width"
      :height="height"
      :loading="loadingTypeComp"
      decoding="async"
      draggable="false"
      @load="imageLoaded"
    >

    <div
      v-if="!hidePreloader"
      class="absolute z-0 animate-pulse w-full h-full rounded-xl top-0 left-0 p-3 after:flex after:h-full after:rounded-md"
      :class="[
        cardDark ? 'after:bg-black/50' : 'after:bg-gray-200',
        loaded ? 'invisible' : 'visible'
      ]"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

const props = defineProps({
  imageUrl: {
    type: String,
    default: null,
  },
  alt: {
    type: String,
    default: null,
  },
  width: {
    type: [Number, String,],
    default: null,
  },
  height: {
    type: [Number, String,],
    default: null,
  },
  loadingType: {
    type: String,
    default: null,
  },
  classes: {
    type: Array,
    default: null,
  },
  cardDark: {
    type: Boolean,
    default: false,
  },
  animationUrl: {
    type: String,
    default: null,
  },
  blockVideoAutoplay: {
    type: Boolean,
    default: false,
  },
  hidePreloader: {
    type: Boolean,
    default: false,
  },
  allowVideoOnHover: {
    type: Boolean,
    default: false,
  },
  forceVideoOnHover: {
    type: Boolean,
    default: false,
  },
  forceVideoAutoplay: {
    type: Boolean,
    default: false,
  },
});

const uiStore = useUiStore();
const {
  uiSettings,
} = storeToRefs(uiStore);

const loaded = ref(false);
const VideoRef = ref();

const classesComp = computed(() => props.classes.join(' '));
const loadingTypeComp = computed(() => props.loadingType ?? 'lazy');

function imageLoaded() {
  loaded.value = true;
}

function autoPlayVideo() {
  if (!VideoRef.value) { return; }

  if (!props.blockVideoAutoplay && (uiSettings.value?.videoAutoplay || props.forceVideoAutoplay)) {
    VideoRef.value.play();
  }
  loaded.value = true;
}

function hoverVideo(play) {
  if (!props.forceVideoOnHover && (uiSettings.value?.videoAutoplay || props.forceVideoAutoplay)) { return; }

  if (VideoRef.value && (props.allowVideoOnHover || props.forceVideoOnHover)) {
    if (play) {
      VideoRef.value.play();
    } else {
      VideoRef.value.pause();
    }
  }
}
</script>
